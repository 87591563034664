<template>
  <div class="viewer-wrapper" is-vertical is-parent>
    <viewer
      ref="viewer"
      :options="options"
      :images="images"
      class="viewer"
      @inited="inited"
    >
      <template #default="scope">
        <figure class="images">
          <div v-for="src in scope.images" :key="src" class="image-wrapper">
            <img
              class="image"
              :src="src"
              :data-source="src"
            >
          </div>
        </figure>
      </template>
    </viewer>
  </div>
</template>
<script>
export default {
  props: ['images'],
  data() {
    return {
      options: {
        inline: true,
        button: false,
        navbar: false,
        title: false,
        toolbar: true,
        tooltip: false,
        movable: true,
        zoomable: true,
        rotatable: true,
        scalable: true,
        transition: true,
        fullscreen: true,
        keyboard: true,
        url: 'data-source'
      }
    }
  },
  methods: {
    inited(viewer) {
      this.$viewer = viewer;
    }
  }
}
</script>
<style lang="scss" scoped>
  .viewer-wrapper {
    position: relative;
    background: #333;
    height: 100%;
  }
  .viewer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;

    .images {
      height: 100%;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 5px;
      .image-wrapper {
        display: inline-block;
        width: calc(33% - 20px);
        margin: 5px 5px 0 5px;
        .image {
          width: 100%;
          cursor: pointer;
          display: inline-block;
        }
      }
    }
  }
</style>
