<template>
  <b-modal id="exams-modal" hide-header hide-footer centered size="xl">
    <div class="header d-flex flex-row justify-content-between">
      <p class="title">Exames realizados</p>
      <v-close class="close" @click="$bvModal.hide('exams-modal')" />
    </div>
    <div class="body d-flex flex-row">
      <div class="sidebar">
        <div v-for="year in Object.keys(this.groupExamsByYear).sort((a, b) =>  b - a)" :key="year" class="year-group">
          <p class="year-title">{{ year }}</p>
          <ul>
            <li :class="{ active: activeExam.length && activeExam[0].id === exams.id }"
              v-for="(exams, index) of groupExamsByYear[year]" :key="index" @click="showDocuments(exams)">
              <div class="exam-row">
                <span class="icon">
                    <v-export @click="ev => openFileInNewTab(ev, exams)"/>
                  </span>
                <div class="ellipsis">
                  <span :id="exams[0].id" class="exam">{{ getDocumentName(exams) }}</span>
                  <b-tooltip :target="exams[0].id" placement="bottom">
                    {{ getDocumentName(exams) }}
                  </b-tooltip>
                </div>
                <div>
                  <span class="date">
                    {{ moment(exams[0].created_at).format('DD/MM') }}
                  </span>
                  <span class="tag" v-if="getMedicalReportStatus(exams) === 'PENDING'">
                     Laudo pendente
                  </span>
                  <span class="tag green-bg" v-else-if="getMedicalReportStatus(exams) === 'FINISHED'">
                     Laudado
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="exams">
        <div class="tabs">
          <span class="tab" @click="examsCurrentTab = 'exame'" :class="{ active: examsCurrentTab == 'exame' }">
            Ver exame
          </span>
          <span class="tab" :class="{ active: examsCurrentTab == 'laudo' }" v-if="activeMedicalReport"
            @click="getMedicalReportTemporaryUrl">Ver laudo</span>
        </div>
        <div class="tab-content">
          <b-skeleton-img height="500px" v-if="loading"></b-skeleton-img>
          <div class="medical-report" v-else-if="examsCurrentTab === 'laudo' && activeMedicalReport.temporaryUrl">
            <div class="link-container">
              <router-link
                :to="`/laudo-medico/${activeMedicalReport.attendance.patient_id}/${activeMedicalReport.attendance_id}/${activeMedicalReport.id}`"
                target="_blank">
                Visualizar laudo completo
              </router-link>
            </div>
            <div class="report-pdf">
              <iframe :src="activeMedicalReport.temporaryUrl" width="100%" height="500px" />
            </div>
          </div>
          <div v-else-if="!activeExam.length" class="placeholder-text">
            Selecione um exame para visualizar
          </div>
          <!-- this.activeExam.medicalReportUrl -->
          <iframe v-else-if="activeExam.length && isPdf" :src="urls[0]" width="100%" height="500px"
            style="border: none !important;" />
          <ImageViewer v-else-if="activeExam.length && !isPdf" :images="urls" />
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import moment from 'moment'
import Close from '@/assets/icons/close.svg'
import Export from '@/assets/icons/export.svg'
import ImageViewer from '@/components/General/ImageViewer'
import { mergeAllPDFs } from '@/utils/PDFHelper'

export default {
  components: {
    'v-close': Close,
    'v-export': Export,
    ImageViewer,
  },
  props: {
    exams: Array
  },
  async mounted() {
    this.groupExamsByYear = this.groupByYear(this.exams);
  },
  data() {
    return {
      examsCurrentTab: 'exame',
      activeExam: [],
      activeMedicalReport: null,
      groupExamsByYear: {},
      laudo: {
        form: null
      },
      urls: [],
      loading: false
    }
  },
  computed: {
    isPdf() {
      return this.activeExam.some(doc => doc.path.includes('.pdf'))
    }
  },
  methods: {
    moment,
    groupByYear(groupedAttendanceFiles) {
      return groupedAttendanceFiles.reduce((acc, attendanceFiles) => {
        const year = moment(attendanceFiles[0].created_at).format('YYYY');
        if (!acc[year]) acc[year] = [];
        acc[year].push(attendanceFiles);
        return acc;
      }, {})
    },
    async getDocTemporaryUrl(path) {
      const response = await this.api.getDocTemporaryUrl(path)
      this.urls.push(response.data)
    },
    async showDocuments(documents) {
      this.urls = []
      this.activeExam = []
      this.loading = true
      this.examsCurrentTab = 'exame'
      try {
        if (documents.some(doc => doc.path.includes('.pdf'))) {
          const url = await mergeAllPDFs(documents)
          this.urls.push(url)
        } else {
          documents.forEach(async doc => {
            await this.getDocTemporaryUrl(doc.path)
          })
        }
        this.activeExam = documents
      } catch (error) {
        this.$toat.error(error.message)
      } finally {
        this.loading = false
      }
    },
    getDocumentName(documents) {
      if (documents.length > 1) {
        return `${documents[0].group_name}`
      }
      return `${documents[0].custom_name}`
    },
    getMedicalReportStatus(documents) {
      const exam = documents.find(el => {
        return el.medical_report
      })
      if (exam)
        return exam.medical_report.status
      else
        return false
    },
    async getMedicalReportTemporaryUrl() {
      try {
        this.loading = true
        this.examsCurrentTab = 'laudo'
        const response = await this.api.getDocTemporaryUrl(this.activeMedicalReport.path)
        this.activeMedicalReport.temporaryUrl = response.data
        this.loading = false
      } catch (error) {
        this.$toast.error("Não foi possível obter o laudo deste exame")
      }
    },
    setActiveExamMedicalReport() {
      this.activeMedicalReport = null
      this.activeExam.map(exam => {
        if (exam.medical_report && exam.medical_report.status === 'FINISHED') {
          this.activeMedicalReport = exam.medical_report
          const medicalReportfile = exam.medical_report.attendance.file.find(file => {
            return file.type_id === '11'
          })
          this.activeMedicalReport.path = medicalReportfile.path
        }
      })
    },
    async openFileInNewTab(ev, exams) {
      ev.stopPropagation();
      exams.forEach(async exam => {
        const res = await this.api.getDocTemporaryUrl(exam.path)
        window.open(res.data, '_blank')
      })
    },
  },
  watch: {
    exams: function (exams) {
      this.groupExamsByYear = this.groupByYear(exams)
    },
    activeExam: function () {
      this.setActiveExamMedicalReport()
    },
  }
}
</script>
<style lang="scss">
#exams-modal {
  .modal-lg {
    max-width: 880px;
  }

  .modal-body {
    padding: 0 !important;

    .header {
      padding: 24px;
      border-bottom: 1px solid var(--neutral-200);

      .title {
        font-weight: 600;
        font-size: 18px;
        color: var(--type-active);
        margin: 0;
      }

      .close {
        width: 24px;
        height: 24px;
        fill: black;
        cursor: pointer;
      }
    }

    .body {
      .sidebar {
        width: 280px;
        max-height: 550px;
        border-right: 1px solid var(--neutral-200);
        overflow-y: auto;

        .year-group {
          margin: 24px 0;

          .year-title {
            font-weight: 700;
            font-size: 14px;
            color: var(--type-active);
            margin-left: 24px;
            margin-bottom: 8px;
          }

          ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
              display: flex;
              flex-direction: row;
              padding: 4px 24px;
              font-weight: 600;
              font-size: 12px;
              color: var(--type-placeholder);
              position: relative;
              cursor: pointer;

              .exam-row {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;

                .icon {
                  position: absolute;
                  top: 8px;
                  right: -20px;
                }

                .tag {
                  color: var(--type-active);
                  background-color: var(--light-orange-200);
                  padding: 2px 10px;
                  border-radius: 100px;
                  margin-left: 10px;
                }

                .green-bg{
                  background-color: #DBFFF0 !important;
                }

              }

              .date {
                font-size: 11px;
                margin-top: 2px;
              }

              .ellipsis {
                padding-right: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .exam {
                width: 100%;
                font-size: 14px;
                line-height: 21px;
                color: var(--type-active);
              }

              &:hover {
                background: var(--neutral-100);
              }

              &.active {
                span {
                  font-weight: 700;
                }

                background: var(--neutral-100);

                &:before {
                  width: 4px;
                  height: 100%;
                  background-color: var(--orange);
                  position: absolute;
                  top: 0;
                  left: 0;
                  content: ' ';
                }
              }
            }
          }
        }
      }

      .exams-centered {
        flex: 1;
        display: flex;
        justify-content: center;

        .tabs {
          height: 72px;
          border-bottom: 1px solid var(--neutral-200);
          display: flex;
          align-items: center;

          .tab {
            font-weight: 600;
            font-size: 16px;
            color: var(--type-active);
            padding: 22px 24px;
            cursor: pointer;
            border-bottom: 3px solid var(--neutral-000);

            &.active {
              color: var(--orange);
              border-bottom: 4px solid var(--orange);
            }
          }
        }

        .tab-content {
          padding: 0 !important;
          height: 500px;

          .exam-images {
            width: 200px;
            text-align: center;
            float: left;
            display: flex;
            flex-direction: column;

            img {
              margin: 5px;
              border-radius: 8px;
              cursor: pointer;
            }
          }

          .form-control {
            border-radius: 8px !important;
            height: 200px;
          }

          .sub-title {
            font-weight: 650;
            font-size: 16px;
            color: var(--type-active);
            padding-bottom: 5px;
          }

          .download-link {
            font-weight: 600;
            font-size: 14px;
            font-family: Nunito Sans;
            color: #305BF2;
            padding-bottom: 5px;
          }
        }
      }

      .exams {
        flex: 1;

        .tabs {
          height: 72px;
          border-bottom: 1px solid var(--neutral-200);
          display: flex;
          align-items: center;

          .tab {
            font-weight: 600;
            font-size: 16px;
            color: var(--type-active);
            padding: 22px 24px;
            cursor: pointer;
            border-bottom: 3px solid var(--neutral-000);

            &.active {
              color: var(--orange);
              border-bottom: 4px solid var(--orange);
            }
          }
        }

        .tab-content {
          padding: 0;
          height: 500px;

          .exam-images {
            width: 200px;
            text-align: center;
            float: left;
            display: flex;
            flex-direction: column;

            img {
              margin: 5px;
              border-radius: 8px;
              cursor: pointer;
            }
          }

          .form-control {
            border-radius: 8px !important;
            height: 200px;
          }

          .sub-title {
            font-weight: 650;
            font-size: 16px;
            color: var(--type-active);
            padding-bottom: 5px;
          }

          .download-link {
            font-weight: 600;
            font-size: 14px;
            font-family: Nunito Sans;
            color: #305BF2;
            padding-bottom: 5px;
          }

          .placeholder-text {
            width: 100%;
            height: 300px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--type-placeholder);
          }

        }

        .medical-report {
          .link-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: end;
            height: 40px;

            a {
              font-weight: 600;
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin-right: 10px;
            }

            a:hover {
              text-decoration: none !important;
            }
          }

          .report-pdf {
            height: 450px;

            iframe {
              display: flex;
              border: none;
              overflow: hidden !important;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
